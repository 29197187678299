var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"创意审核","visible":_vm.dialogTableVisible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('el-form',{ref:"formRef",staticStyle:{"width":"350px","margin":"0 auto"},attrs:{"label-width":"100px","label-suffix":"：","model":_vm.formCustom,"rules":_vm.rules}},[(_vm.editData.showType != 3)?_c('el-form-item',{attrs:{"label":"横向图","prop":"materialUrl"}},[_c('el-image',{staticStyle:{"height":"80px"},attrs:{"src":_vm.formCustom.materialUrl,"preview-src-list":[
          _vm.formCustom.materialUrl,
          _vm.formCustom.verticalImgUrl,
          _vm.formCustom.squareImgURL,
        ]}})],1):_vm._e(),(_vm.editData.showType != 3)?_c('el-form-item',{attrs:{"label":"竖向图","prop":"verticalImgUrl"}},[_c('el-image',{staticStyle:{"height":"80px"},attrs:{"src":_vm.formCustom.verticalImgUrl,"preview-src-list":[
          _vm.formCustom.materialUrl,
          _vm.formCustom.verticalImgUrl,
          _vm.formCustom.squareImgURL,
        ]}})],1):_vm._e(),(_vm.editData.squareImgURL)?_c('el-form-item',{attrs:{"label":"方形图","prop":"squareImgURL","required":""}},[_c('el-image',{staticStyle:{"height":"80px","width":"80px"},attrs:{"src":_vm.formCustom.squareImgURL,"preview-src-list":[
          _vm.formCustom.materialUrl,
          _vm.formCustom.verticalImgUrl,
          _vm.formCustom.squareImgURL,
        ]}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"审核状态","prop":"auditStatus"}},[_c('el-radio-group',{on:{"change":function($event){return _vm.$refs.formRef.clearValidate()}},model:{value:(_vm.formCustom.auditStatus),callback:function ($$v) {_vm.$set(_vm.formCustom, "auditStatus", $$v)},expression:"formCustom.auditStatus"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("审核通过")]),_c('el-radio',{attrs:{"label":3}},[_vm._v("审核驳回")])],1)],1),_c('el-form-item',{attrs:{"label":"审核意见","prop":"remarks","rules":[
        { required: _vm.formCustom.auditStatus == 3, message: '请输入审核意见' },
      ]}},[_c('el-input',{staticStyle:{"width":"250px"},attrs:{"type":"textarea"},model:{value:(_vm.formCustom.remarks),callback:function ($$v) {_vm.$set(_vm.formCustom, "remarks", $$v)},expression:"formCustom.remarks"}})],1),_c('div',{staticClass:"btnBox disfr ac jc"},[_c('el-button',{staticClass:"mhor10 flex1",attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确定")]),_c('el-button',{staticClass:"mhor10 flex1",on:{"click":_vm.cancel}},[_vm._v("取消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }