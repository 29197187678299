<template>
  <div class="originalty">
    <el-row :gutter="10">
      <el-col :span="6">
        <el-input
          placeholder="请输入搜索内容"
          v-model="searchForm.ideaName"
          class="input-with-select"
          clearable
        >
          <div slot="prepend">创意名称</div>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-select v-model="searchForm.status" placeholder="状态" clearable>
          <el-option
            v-for="(item, index) in originalityStatus"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="6">
      <el-col :span="8">
        <el-row type="flex" align="middle">
          <span style="white-space: nowrap"> 提交日期：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            v-model="searchForm.date"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-row>
      </el-col>
      <el-col :span="4"
        ><el-button type="primary" icon="el-icon-search" @click="searchFn()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <PageTable
      id="statisticsTable"
      class="pageTable"
      :columns="initcolumns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template #operation="{ row }">
        <el-button
          v-has="'review'"
          type="text"
          :disabled="row.status !== 2"
          @click="toExamine(row)"
          >审核</el-button
        >
        <el-button type="text" v-has="'history'" @click="showHistory(row)"
          >历史记录</el-button
        >
      </template>
      <template #status="{ row }">
        <el-tag :type="row.status | tagType(originalityStatus)">
          {{ row.status | filterExamineName }}
        </el-tag>
      </template>
      <template #showType="{ row }">
        <span>{{ row.showType | filterShowTypeName }}</span>
      </template>
    </PageTable>
    <examineDrawer
      @submit="initList"
      :editData="editData"
      v-model="examineDialog"
    ></examineDrawer>
    <historyDrawer
      @handleChangePage="handleChangePageHistory"
      :editData="editData"
      :historyLogs="historyLogs"
      v-model="historyDialog"
    ></historyDrawer>
  </div>
</template>

<script>
import examineDrawer from "./examineDrawer.vue";
import historyDrawer from "./historyDrawer.vue";
import { getIdeaAuditList, getIdeaLog } from "@/common/js/api";
import moment from "moment";
import PageTable from "@/components/PageTableSort";
import {
  originalityShowTypes,
  originalityStatus,
} from "@/common/constant/constant.js";

export default {
  components: {
    examineDrawer,
    historyDrawer,
    PageTable,
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      searchForm: {
        ideaName: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        date: [start, end],
      },
      initcolumns: [
        { key: "ideaName", label: "创意名称" },
        // { slot: "showType", label: "展示类型" },
        { key: "planName", label: "推广计划" }, //?
        { key: "groupName", label: "推广组" },
        { key: "appName", label: "所属应用" },
        { key: "developerName", label: "所属开发者" },
        { slot: "status", label: "审核状态" },
        { key: "resolveUserName", label: "审核员" },
        { slot: "operation", label: "操作" },
      ],
      columns: [],
      tableData: {
        list: [],
        size: 10,
        page: 1,
        total: 10,
      },
      examineDialog: false,
      editData: {},
      historyDialog: false,
      historyLogs: {},
      originalityStatus,
    };
  },
  mounted() {
    this.initList();
  },
  filters: {
    filterExamineName(val) {
      let sec = originalityStatus.find((a) => a.value === val);
      return sec ? sec.label : "";
    },
    filterShowTypeName(val) {
      let sec = originalityShowTypes.find((a) => a.value === val);
      return sec ? sec.label : "";
    },
  },
  methods: {
    async initList(query) {
      const { date, ...rest } = this.searchForm;
      const params = {
        ...rest,
        ...query,
      };
      if (date) {
        params.submitStart = date[0];
        params.submitEnd = date[1];
      }
      if (params.status === "") params.status = -1;
      let res = await getIdeaAuditList(params);
      this.tableData.list = res.data.ideaList;
      this.tableData.total = res.data.count;
      this.tableData.page = this.searchForm.pageIndex;
    },
    handleChangePage({ page, size }) {
      this.searchForm.pageIndex = page;
      this.searchForm.pageSize = size;
      this.initList();
    },
    toExamine(row) {
      this.editData = row;
      this.examineDialog = true;
    },
    async showHistory(row) {
      let res = await getIdeaLog({
        pageIndex: 1,
        pageSize: 10,
        ideaId: row.ideaID,
      });
      if (!res) return;
      this.historyLogs = {
        list: res.data.respList,
        total: res.data.total,
      };
      this.editData = row;
      this.historyDialog = true;
    },
    async handleChangePageHistory({ page, size }) {
      let res = await getIdeaLog({
        pageIndex: page,
        pageSize: size ? size : 10,
        ideaId: this.editData.ideaID,
      });
      if (!res) return;
      this.historyLogs = {
        list: res.data.respList,
        total: res.data.total,
      };
    },
    searchFn() {
      this.searchForm.pageIndex = 1;
      this.initList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../common/css/common.scss";
.originalty {
  .seriesLabel {
    height: 32px;
  }
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 80px;
  }
  /deep/.el-date-editor--date {
    width: 100%;
  }
}
</style>
