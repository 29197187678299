<template>
  <div style="width: 98%">
    <el-table
      border
      stripe
      :data="tableData.list"
      :row-key="rowKey"
      :cell-style="tableRowClassName"
      :height="height"
      @select="handleSelect"
      @selection-change="handleSelectionChange"
      @sort-change="sortChange"
    >
      <slot name="prefix"></slot>
      <el-table-column
        v-for="item in columns"
        :key="item.key ? item.key : item.slot"
        :prop="item.key ? item.key : item.slot"
        :label="item.label"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
        :align="item.align ? item.align : 'center'"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="{ row }">
          <span v-if="item.key">{{ row[item.key] }}</span>
          <slot v-if="item.slot" :name="item.slot" :row="row"></slot>
        </template>
        <template #header="{ column, $index }" v-if="item.labelSlot">
          <slot :name="item.labelSlot" :column="column"></slot>
        </template>
      </el-table-column>
      <slot></slot>
    </el-table>
    <el-pagination
      class="pagination-wapper"
      @size-change="handleSizeChange"
      @current-change="handleChangePage"
      :current-page="parseInt(tableData.page) || 1"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="parseInt(tableData.size) || 10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="parseInt(tableData.total) || 0"
      v-if="isPagination"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "PageTable",
  props: {
    rowKey: {
      type: String,
    },
    columns: {
      required: true,
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      required: true,
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0,
          list: [],
        };
      },
    },
    height: {
      type: String,
    },
    isPagination: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isCurClassName: {
      //返利审核页面 自定义列表背景颜色
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    handleSizeChange(size) {
      let page = this.tableData.page;
      if (page !== 1) {
        // 当不是在第一页更改size时，更新tableData里面的数据。以防再次触发handleChangePage
        page = 1;
        this.tableData.size = size;
        this.tableData.page = 1;
      }
      this.$emit("page-change", { page, size });
    },
    handleChangePage(page) {
      this.$emit("page-change", { page, size: this.tableData.size });
    },
    handleSelect(selection, row) {
      this.$emit("select", selection, row);
    },
    handleSelectionChange(selection) {
      this.$emit("selection-change", selection);
    },
    sortChange(row) {
      this.$emit("customSortChange", row);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.reviewStatus == 1 && this.isCurClassName) {
        return "background-color: #67C23A";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pagination-wapper {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-table__header {
  th {
    background: #ebebeb66;
    color: #000000d9;
    div {
      font-weight: 700;
    }
  }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  // width: 18px !important;
  height: 15px !important;
}
/deep/.el-table__fixed::before {
  height: 0 !important;
}
/deep/.el-table__fixed-right::before {
  height: 0 !important;
}
// /deep/.el-table .offShelf {
//   background-color:#67C23A !important;
// }
</style>
